<template>
    <div class="">
        <d3-network :net-nodes="izo.nodes" :net-links="izo.links" :options="izo.options" />
    </div>
</template>
<style src="vue-d3-network/dist/vue-d3-network.css" />

<script>
    import D3Network from 'vue-d3-network'

    export default {
        name: "test_graph",
        components: {
            D3Network
        },
        data () {
            return {
                izo: {
                    nodes: [],
                    links:  [],
                    options:
                        {
                            force: 2000,
                            nodeSize: 20,
                            nodeLabels: true,
                            linkWidth:3
                        }
                }

            }
        },
        mounted() {
            const izo = require('./izo')
            this.izo.nodes = izo.nodes
            this.izo.links = izo.links
        }
    }
</script>

<style scoped>

</style>
